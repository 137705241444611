
import React from "react"
import TemplateEditor from "../Pdfgenarot/Pdfgenator"
const Tempalte = () => {
    return (
        <>
        ok
        <div style={{marginTop:"40px"}}>
        <TemplateEditor/>
        </div>
  


        </>



    )
}

export default Tempalte;